<template>
  <div id="merchantTab-actionDialog">
    <el-dialog
        title="车辆操作"
        :visible.sync="show"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        @closed="reset"
    >
      <el-form
          :model="actionForm"
          :rules="ruleForm"
          ref="actionForm"
          label-width="80px"
      >
        <el-form-item label="车牌号" prop="plateNum">
          <el-input :disabled="!!carId" v-model="actionForm.plateNum" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="发放金额" prop="releaseAmount">
          <el-input
              v-model.number="actionForm.releaseAmount"
              placeholder="请输入发放金额"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="carId" label="剩余金额" prop="availableAmount">
          <el-input
              v-model.number="actionForm.availableAmount"
              placeholder="请输入剩余金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="发放类型" prop="releaseType">
          <el-select
              v-model="actionForm.releaseType"
              placeholder="请选择发放类型"
          >
            <el-option
                :label="item.label"
                :value="item.value"
                v-for="item of select_durationType"
                :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="actionForm.contactName" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="actionForm.contactPhone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="actionForm.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
            type="primary"
            size="medium"
            @click="confirmBtn"
            :loading="loading"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      carId: "",
      merchantId: this.$route.params.id,
      actionForm: {
        plateNum: "",
        releaseAmount: "",
        availableAmount: "",
        releaseType: "",
      },
      ruleForm: {
        plateNum: [
          {required: true, message: '请输入车牌号', trigger: 'blur'},
        ],
        releaseType: [
          {required: true, message: '请选择发放类型', trigger: 'change'},
        ],
        availableAmount: [
          {required: true, message: '请输入剩余金额', trigger: 'change'},
        ],
        releaseAmount: [
          {required: true, message: "请输入发放金额", trigger: "blur"},
          {type: "number", message: "发放金额必须为数字值"},
        ],
      },
    };
  },
  methods: {
    reset() {
      this.carId = ""
      this.$set(this, "actionForm", {
        plateNum: "",
        releaseAmount: "",
        availableAmount: "",
        releaseType: "",
        contactName: "",
        contactPhone: "",
        remark: "",
      });
      this.$refs["actionForm"].resetFields();
    },
    confirmBtn() {
      this.$refs["actionForm"].validate(async (valid) => {
        if (valid) {
          try {
            let url = "/freeCar/add"
            let params = {
              ...this.actionForm
            }

            if (!this.carId) {
              params.merchantId = this.merchantId
              delete params.availableAmount
            } else {
              url = "/freeCar/edit"
              params.carId = this.carId
              delete params.plateNum
            }

            this.loading = true;
            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.$emit("refreshList")
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
            this.loading = false;
          }
        }
      });

    },
  },
};
</script>
<style lang="scss">
#merchantTab-actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;

      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select, .el-date-editor {
          width: 100%;
        }

        .black-white-list {
          li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .el-date-editor {
              width: 110px;

              .el-input__inner {
                padding-right: 10px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
